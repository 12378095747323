.table {
  td {
    min-width: 150px;
  }
  button {
    margin-left: 5px;
  }
}
.MuiIconButton-root {
  border: none !important;
  padding: none !important;
  color: black;
}
