@import "./general/variables";
@import "./general/breakpoints";
@import "./general/mixins";
@import "./general/layout";
@import "./components/loadingBox";
@import "./components/messageBox";
@import "./components/navbar";
@import "./components/menuMobile";
@import "./components/button";
@import "./components/form";
@import "./screens/singinScreen";
@import "./components/product";
@import "./screens/productScreen";
@import "./screens/shopScreen";
@import "./screens/cartScreen";
@import "./screens/shippingScreen";
@import "./components/cardsSection";
@import "./screens/placeOrderScreen";
@import "./screens/orderScreen";
@import "./screens/adminScreen";
@import "./components/table";
@import "./screens/productEditScreen";
@import "./components/carousel";
@import "./screens/aboutScreen";
@import "./screens/galleryScreen";
@import "./components/placeholder";
@import "./screens/notFoundScreen";
@import "./components/galleryImage";
@import "./screens/homeScreen";
@import "./components/illustration";
@import "./components/video";
@import "./components/footer";
@import "./components/arrowUp";

#root {
  &.show {
    @include show-root;
  }
}
a {
  text-decoration: none;
  color: inherit;
}
.notyf {
  cursor: pointer;
}
.notyf__dismiss-btn {
  border: none !important;
}
.swal2-shown {
  padding-right: 0 !important;
}
.SRLOpened,
.swal2-shown,
.ReactModal__Body--open {
  overflow: hidden !important;
  #root {
    overflow-y: scroll;
  }
}
.ril__toolbar {
  background-color: transparent !important;
  button {
    border: none !important;
  }
}
.slick-slider {
  button {
    display: none !important;
  }
}
.cookie-consent {
  background-color: white !important;
  color: black !important;
  background-color: whitesmoke !important;
  .cookie-consent-content {
    flex: auto !important;
  }
  .cookie-consent-btn {
    background-color: transparent !important;
  }
}
