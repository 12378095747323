.shipping {
  @include lg {
    @include margin-center;
  }
  .shipping-container {
    padding-top: 130px;
    min-height: 90vh;
    @include md {
      padding-top: 150px;
    }
    .shipping-inner {
      width: 100%;
    }
  }
}
