.cards-section {
  .card {
    width: 100%;
    border: 1px $darkColor solid;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    h3 {
      margin-top: 0;
    }
    p {
      margin: 0;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .item-image {
        width: 100px;
        height: 100px;
        float: left;
        margin-right: 1rem;
        .item-image-inner {
          opacity: 0;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .item-content {
        min-height: 50px;
        margin-left: 100px;
        .item-name,
        .item-price {
          font-weight: bold;
        }
        @include xxs {
          .item-name {
            float: left;
            width: 60%;
          }
          .item-price {
            float: right;
          }
        }
      }
    }
    &.total-amount {
      text-align: right;
      .total {
        margin-bottom: 0;
      }
    }
  }
  button {
    width: 100%;
  }
  .show {
    @include fade-in;
  }
}
