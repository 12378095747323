.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.carousel-item-next {
  &:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
.carousel-item-prev {
  &:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.carousel-item.active {
  display: block;
}
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  right: 0;
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  left: 0;
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 0;
  @include md {
    display: none;
  }
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: black;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.7;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  background-color: $primaryColor;
  opacity: 1;
}
@include reduced-motion {
  .carousel-item,
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
