.nav-mobile {
  font-family: $font;
  font-weight: bold;
  text-transform: uppercase;
  position: fixed;
  right: -100%;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
  padding: 1rem 3vw;
  font-size: $largeMobile;
  transition: 0.3s;
  z-index: 1000;
  &.show {
    right: 0;
    transition: 0.3s;
  }
  @include nav-options;
  .close {
    text-align: right;
    margin-right: 1.8rem;
    margin-top: 1.8rem;
    @include md {
      margin-top: 2.4rem;
    }
  }
  ul {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    li {
      margin: 30px 0 30px 0;
      text-align: center;
      line-height: 30px;
    }
  }
}
