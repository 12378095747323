@mixin xxs {
  @media (min-width: #{$screen-xxs-min}) {
    @content;
  }
}
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin xs-height {
  @media (min-height: #{$screen-xs-min-height}) {
    @content;
  }
}
@mixin sm-height {
  @media (min-height: #{$screen-sm-min-height}) {
    @content;
  }
}
@mixin reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}
@mixin margin-center {
  @include md {
    margin-left: 15vw;
    margin-right: 15vw;
  }
  @include lg {
    margin-left: 22vw;
    margin-right: 22vw;
  }
}
@mixin nav-options {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    .active {
      color: $primaryColor;
      fill: $primaryColor;
    }
  }
}
@mixin spin {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@mixin fade-out {
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation-name: fade-out;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin fade-in {
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin ellipsis {
  @keyframes ellipsis {
    to {
      width: 0.95em;
    }
  }
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
}
@mixin scaleIn {
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@mixin scaleOut {
  @-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }
  @keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }
  -webkit-animation: scale-out-center 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@mixin show-root {
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
