.not-found-screen {
  .not-found-screen-container {
    min-height: 95vh;
    text-align: center;
    .title {
      font-family: $font;
      margin: 0;
      font-size: 3rem;
      @include sm {
        font-size: 4rem;
      }
      @include md {
        font-size: 5rem;
      }
    }
    button {
      font-family: $font;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 3rem;
      width: 50%;
      @include md {
        width: 30%;
      }
    }
  }
}
