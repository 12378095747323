.gallery {
  @include lg {
    margin-left: 10%;
    margin-right: 10%;
  }
  .filters {
    user-select: none;
    padding: 8px;
    text-align: center;
    @include md {
      font-size: 0.9rem;
    }
    .filter {
      text-transform: uppercase;
      display: inline-block;
      margin: 5px;
      font-family: $font;
      font-weight: bold;
      cursor: pointer;
      transition: 0.2s;
      &.active,
      &:hover {
        color: $secondaryColor;
      }
    }
  }
  .gallery-loading {
    height: 80vh;
    svg {
      width: 90px;
    }
  }
  .gallery-images {
    line-height: 0;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    @include sm {
      column-count: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
      -webkit-column-gap: 10px;
      -moz-column-gap: 10px;
      column-gap: 10px;
    }
    @include md {
      column-count: 3;
      -moz-column-count: 3;
      -webkit-column-count: 3;
    }
    @include lg {
      column-count: 4;
      -moz-column-count: 4;
      -webkit-column-count: 4;
    }
  }
  .gallery-images-container {
    &.hidden {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      height: 0;
    }
    &:not(.hidden) {
      .gallery-image {
        cursor: pointer;
      }
    }
    .gallery-image {
      width: 100% !important;
      height: auto !important;
    }
    &.invisible {
      visibility: hidden;
    }
    &.visible {
      visibility: visible;
    }
    &.hide-instant {
      opacity: 0;
    }
  }

  .hide {
    @include fade-out;
  }
  .show {
    @include fade-in;
    position: relative;
    visibility: visible;
    z-index: 1;
  }
}
.SRLCaptionText {
  font-family: $font !important;
  font-weight: bold !important;
}
