button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid !important;
  transition: 0.3s;
  &.primary {
    background-color: $darkColor;
    color: white;
    &:hover:not(:disabled),
    &.active {
      background-color: transparent;
      color: $darkColor;
    }
    &:disabled {
      cursor: auto;
      border: 1px solid $disabledColor;
      background-color: $disabledColor;
      color: white;
    }
  }
  &.secondary {
    background-color: transparent;
    &:hover:not(:disabled),
    &.active {
      color: white;
      background-color: $darkColor;
      border: 1px $darkColor solid !important;
    }
    &:disabled {
      cursor: auto;
      border: 1px solid $disabledColor;
      color: $disabledColor;
    }
  }
  &.dangerous {
    background-color: $dangerColor;
    color: white;
    &:hover:not(:disabled),
    &.active {
      background-color: transparent;
      color: $dangerColor;
    }
    &:disabled {
      cursor: auto;
      border: 1px solid $disabledColor;
      background-color: $disabledColor;
      color: white;
    }
  }
  &.dangerous-outline {
    background-color: transparent;
    border: 1px $dangerColor solid !important;
    color: $dangerColor;
    &:hover:not(:disabled),
    &.active {
      color: white;
      background-color: $dangerColor;
      border: 1px $dangerColor solid !important;
    }
    &:disabled {
      cursor: auto;
      border: 1px solid $disabledColor;
      color: $disabledColor;
    }
  }
}
