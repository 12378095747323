.product-edit {
  padding-top: 130px;
  min-height: 90vh;
  @include md {
    padding-top: 150px;
  }
  @include lg {
    @include margin-center;
  }
  .clear-images-btn {
    margin-top: 1rem;
  }
  .preview {
    display: inline-block !important;
    text-align: center;
    img {
      margin-right: 4px;
      width: 20vw;
      @include lg {
        width: 100px;
      }
    }
  }
}
