.about {
  margin-bottom: 20vh;
  @include lg {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0;
  }
  .about-container {
    min-height: 75vh;
  }
  .text {
    .title {
      font-family: $font;
      font-weight: bold;
      line-height: 1;
      font-size: $extraLargeMobile;
      @include md {
        font-size: $extraLargeDesktop;
      }
    }
    p {
      font-size: 0.95rem;
    }
    @include md {
      width: 48%;
    }
  }
  .video {
    margin-top: 10px;
    @include md {
      margin-top: 0;
      width: 48%;
    }
  }
}
