.cart {
  padding-top: 130px;
  min-height: 90vh;
  @include md {
    padding-top: 150px;
  }
  @include lg {
    @include margin-center;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .cart-items {
    font-size: 83%;
    button {
      display: none;
    }
    .icon {
      display: block;
      margin-top: 4px;
      width: 28px;
    }
    @include sm {
      font-size: 100%;
      button {
        display: block;
      }
      .icon {
        display: none;
      }
    }

    li {
      h3 {
        margin: 0;
      }
      .item-image {
        width: 115px;
        height: 115px;
        float: left;
        margin-right: 1rem;
        @include sm {
          width: 140px;
          height: 140px;
        }
        .item-image-inner {
          opacity: 0;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .item-content {
        margin-left: 115px;
        width: calc(100% - 115px);
        min-height: 57.5px;
        @include sm {
          margin-left: 140px;
          width: calc(100% - 140px);
          min-height: 70px;
        }
        @include xxs {
          .item-name {
            width: 65%;
          }
          .item-name,
          .item-size,
          .item-qty {
            float: left;
            span {
              display: none;
              @include sm {
                display: block;
                display: inline-block;
              }
            }
          }
          .item-price,
          .item-remove {
            float: right;
          }
          .item-size {
            margin-right: 1rem;
          }
        }
      }
      .line {
        margin-top: 1rem;
        margin-bottom: 1rem;
        background: $darkColor;
        width: 100%;
        height: 1px;
      }
    }
  }
  .cart-total {
    text-align: right;
    li {
      margin-bottom: 0.5rem;
    }
    h2 {
      margin: 0;
    }
    button {
      width: 100%;
    }
  }
  .show {
    @include fade-in;
  }
}
