.video {
  &.desktop {
    display: none;
    @include md {
      display: block;
    }
  }
  &.mobile {
    display: block;
    @include md {
      display: none;
    }
  }
  position: relative;
  video {
    opacity: 0;
    width: 100%;
    display: block;
  }
  .play {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 10px;
    svg {
      width: 40px;
    }
  }
  .poster {
    position: absolute;
    top: 0;
    left: 0;
    img {
      display: block;
      width: 100%;
      display: none;
    }
  }
  .video-subtitle-container {
    .placeholder {
      float: right;
      margin-top: 5px;
      width: 50%;
      @include md {
        width: 40%;
      }
      .video-subtitle {
        opacity: 0;
        margin: 0;
        text-align: right;
        font-size: 10px;
      }
    }
  }
  .show {
    @include fade-in;
  }
  .hide {
    @include fade-out;
  }
}
