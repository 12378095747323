.product-screen {
  padding-top: 130px;
  min-height: 90vh;
  @include md {
    padding-top: 150px;
  }
  .product-screen-container {
    min-height: 80vh;
    .product-images {
      @include md {
        margin-right: 2rem;
      }
      @include lg {
        margin-right: 3rem;
      }
      .product-image {
        cursor: pointer;
        margin-bottom: 5px;
        @include xxs {
          width: 250px;
          height: 250px;
        }
        @include xs {
          width: 350px;
          height: 350px;
        }
        @include sm {
          width: 500px;
          height: 500px;
        }
        @include md {
          width: 400px;
          height: 400px;
        }
        @include lg {
          width: 450px;
          height: 450px;
        }
        @include xl {
          width: 500px;
          height: 500px;
        }
        .product-image-inner {
          opacity: 0;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
      .image-preview-container {
        @include xxs {
          max-width: 250px;
        }
        @include xs {
          max-width: 350px;
        }
        @include sm {
          max-width: 500px;
        }
        @include md {
          max-width: 400px;
        }
        @include lg {
          max-width: 450px;
        }
        @include xl {
          max-width: 500px;
        }
      }
      .image-preview {
        margin: 0 5px 3px 0;
        display: none;
        cursor: pointer;
        &:nth-child(5),
        &:nth-child(10),
        &:nth-child(15) {
          margin-right: 0px;
        }
        @include md {
          display: inline-block;
          width: 76px;
          height: 76px;
        }
        @include lg {
          width: 86px;
          height: 86px;
        }
        @include xl {
          width: 96px;
          height: 96px;
        }
        .image-preview-inner {
          opacity: 0;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    .product-details {
      @include xxs {
        width: 250px;
      }
      @include xs {
        width: 350px;
      }
      @include sm {
        width: 500px;
      }
      @include md {
        width: 258px;
        height: 485px;
      }
      @include lg {
        height: 545px;
      }
      @include xl {
        height: 605px;
      }
      .name {
        margin-top: 10px;
        @include md {
          margin-top: 0;
        }
      }
      .price {
        color: $primaryColor;
      }
      .description {
        white-space: pre-line;
      }
      .size {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        .size-option {
          display: inline-block;
          text-align: center;
          margin-right: 3px;
          margin-bottom: 5px;
          button {
            width: 40px;
          }
        }
      }
      .add-to-cart {
        margin-top: 1.5rem;
        button {
          width: 100%;
        }
      }
    }
  }
  .show {
    @include fade-in;
  }
}
