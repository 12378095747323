.form {
  margin-top: 0 !important;
  div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:not(.no-flex) {
      display: flex;
      flex-direction: column;
    }
  }
  label {
    text-align: left;
    margin: 0.5rem 0;
    a {
      transition: 0.3s;
      padding-bottom: 2px;
      &:hover {
        opacity: 0.6;
        transition: 0.3s;
        border-bottom: 1px solid $darkColor;
      }
    }
  }
}
input,
select,
textarea,
button {
  border: 1px black solid;
  border-radius: 0;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
}
