.product {
  margin: 1rem;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-family: $font;
  font-weight: 300;
  .product-image {
    opacity: 0;
    width: 235px;
    height: 235px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .product-description {
    min-height: 59px;
    opacity: 0;
    p {
      margin: 0;
      &:first-child {
        margin-top: 7px !important;
        margin: 0;
        font-size: 1.2rem;
      }
    }
    .line {
      width: 50px;
      height: 1px;
      background-color: black;
      margin-left: auto;
      margin-right: auto;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .show {
    @include fade-in;
  }
}
