.order {
  @include lg {
    @include margin-center;
  }
  padding-top: 130px;
  min-height: 90vh;
  @include md {
    padding-top: 150px;
  }
  .order-container {
    .order-inner {
      width: 100%;
    }
  }
  .paypal-button,
  .cancel-button {
    width: 100%;
    @include md {
      width: 49%;
    }
  }
  .paypal-button {
    float: left;
  }
  .deliver-button {
    margin-bottom: 0.5rem;
  }
  .cancel-button {
    float: right;
    &.full-width {
      width: 100%;
    }
  }
}
