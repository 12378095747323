.alert {
  padding: 0.5rem;
  border: 0.1rem solid transparent;
  &.error {
    color: #a02020;
    border: #a02020 1px solid;
    background-color: #ffe0e0;
  }
  &.success {
    color: #20a020;
    border: #20a020 1px solid;
    background-color: #eeffe0;
  }
  &.info {
    color: $secondaryColor;
    border: $secondaryColor 1px solid;
    background-color: #fee0ff;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    transition: 0.3s;
  }
}
