.gallery-image {
  margin-bottom: 10px;
  display: inline-block;
  .gallery-image-inner {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
