header {
  width: 100%;
  font-family: $font;
  font-weight: bold;
  text-transform: uppercase;
  position: fixed;
  z-index: 1000;
  transition: 0.1s;
  &.scrolled {
    background-color: white;
  }
  .brand-logo {
    transition: 0.1s;
    width: 11rem;
    @include lg {
      width: 14rem;
    }
    &.scrolled {
      width: 8rem;
      @include lg {
        width: 10rem;
      }
    }
    .logo-cls-18 {
      fill: $primaryColor;
      transition: 0.1s;
    }
    &:hover {
      .logo-cls-18 {
        fill: $secondaryColor;
        transition: 0.1s;
      }
    }
  }
  .badge {
    background: #ff0000;
    color: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 4.5px;
    vertical-align: top;
    margin-left: -10px;
  }
  @include nav-options;
  .icon {
    width: 1.4rem;
    transition: 0.1s;
    vertical-align: middle;
  }
  .nav-desktop {
    display: none;
    @include lg {
      display: block;
    }
    li {
      font-size: $largeDesktop;
      margin-left: 1.5rem;
      display: inline;
      transition: 0.1s;
      &:hover {
        color: $primaryColor;
        fill: $primaryColor;
        transition: 0.1s;
      }
    }
  }
  .icon-mobile {
    a.active {
      .icon.fill {
        fill: $primaryColor;
      }
    }
    @include lg {
      display: none;
    }
  }
}
