header {
  padding: 1rem 3vw;
}
main {
  padding: 1rem 3vw;
}
section {
  min-height: 90vh;
  h1 {
    margin-top: 0;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &.center {
    justify-content: center;
  }
}
.custom-font {
  font-family: $font;
  font-weight: bold;
}
