.arrow-up {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: white;
  border: 1.5px solid $darkColor;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  text-align: center;
  transition: 0.2s;
  margin: 20px;
  svg {
    width: 30px;
    padding: 2px;
    height: 48px;
    fill: $darkColor;
  }
  &.show {
    @include scaleIn;
  }
  &.hide {
    @include scaleOut;
  }
}
