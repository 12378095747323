.footer {
  @include lg {
    margin-left: 10%;
    margin-right: 10%;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: black;
  }
  h1,
  p {
    margin: 0;
  }
  .shop-now {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    svg {
      @include md {
        width: 300px;
      }
      width: 200px;
      transition: 0.2s;
      &:hover {
        transition: 0.2s;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
  }
  .footer-content {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    .contacts {
      margin-top: 10px;
      svg {
        width: 35px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .joao-lopes {
    text-align: center;
    font-size: 0.9rem;
    a {
      transition: 0.2s;
      &:hover {
        opacity: 0.4;
        transition: 0.2s;
      }
    }
    svg {
      width: 0.9rem;
      vertical-align: middle;
    }
  }
}
.MuiTooltip-popper {
  margin-top: -10px;
}
