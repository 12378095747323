/*General*/
$primaryColor: #a38821;
$secondaryColor: #c3a9e8;
$darkColor: #2d2d2d;
$disabledColor: #d3d3d3;
$dangerColor: #fe2020;
$largeDesktop: 1.1rem;
$largeMobile: 1.2rem;
$extraLargeDesktop: 2.2rem;
$extraLargeMobile: 1.8rem;
/*Placeholder*/
$background: #f6f7f8;
$grey-nuance-lighter: #eeeeee;
$grey-nuance-darker: #dddddd;
$fade-grey: #e8e8e8;
/*Font*/
$font: "Roboto Condensed", sans-serif;
